import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import WebViewer from '@pdftron/webviewer'
@Component({
  selector: 'app-customviewer',
  templateUrl: './customviewer.component.html',
  styleUrls: ['./customviewer.component.css']
})
export class CustomviewerComponent implements OnInit, AfterViewInit {

  @ViewChild('opsviewer', { static: false }) opsviewer: ElementRef;
  wvInstance: any;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    // The following code initiates a new instance of WebViewer.

    WebViewer({
      path: '../../lib',
      initialDoc: 'https://expresspaystub.s3.amazonaws.com/Staging/StaticFiles/From941/Form941_2019.pdf',
      disabledElements: [
        'header',
        'toolsHeader',
        'notesPanel',
        'textPopup',
      ],
    }, this.opsviewer.nativeElement).then(instance => {

      const { Annotations, annotManager } = instance;
      this.wvInstance = instance;

      //remove toolbars
      instance.setHeaderItems(function (header) {
        header.update([]);
      });

      //Set Zoom to fit to width
      let FitMode = instance.FitMode;
      instance.setFitMode(FitMode.FitWidth);


      //#region Get field data
      const getFieldNameAndValue = (field) => {
        // Do something with data
        const { name, value, type } = field;
        console.log(`Name: ${name}, Value: ${value}`);
        if (type == 'Sig') {
          debugger;

        }

        // Check children fields
        field.children.forEach(getFieldNameAndValue);
      }
      //#endregion


      //#region Save Button Get Data
      document.getElementById('btnSaveTax').onclick = function () {
        const fieldManager = annotManager.getFieldManager();
        fieldManager.forEachField(getFieldNameAndValue);
      }

      //#endregion




    })
  }

}
