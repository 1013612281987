<div class="page">

  <div #viewer class="viewer"></div>
  <div class="spacer20"></div>
  <div style="float:right;">
    <button mat-raised-button type="button" id="btnSaveTax" color="primary">
      Save
    </button>
  </div>
  <div style="float:left;">
    <button mat-raised-button type="button" id="btnOpenTax" color="accent">
      Open
    </button>
  </div>
  <div class="clearfix"></div>
</div>
<div class="customdiv">
  <div>
    text required
  </div>
</div>

