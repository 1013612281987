import { Component, ViewChild, OnInit, ElementRef, AfterViewInit } from '@angular/core';
declare const WebViewer: any;

@Component({
  selector: 'app-webviewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.css']
})

export class WebviewerComponent implements OnInit, AfterViewInit {

  // Syntax if using Angular 8+
  // true or false depending on code
  @ViewChild('viewer', { static: false }) viewer: ElementRef;

  wvInstance: any;

  ngOnInit() {
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
  }

  wvDocumentLoadedHandler(): void {
    // you can access docViewer object for low-level APIs
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;
    // and access classes defined in the WebViewer iframe
    const { Annotations } = this.wvInstance;
    const rectangle = new Annotations.RectangleAnnotation();
    rectangle.PageNumber = 1;
    rectangle.X = 100;
    rectangle.Y = 100;
    rectangle.Width = 250;
    rectangle.Height = 250;
    rectangle.StrokeThickness = 5;
    rectangle.Author = annotManager.getCurrentUser();
    annotManager.addAnnotation(rectangle);
    annotManager.drawAnnotations(rectangle.PageNumber);
    // see https://www.pdftron.com/api/web/WebViewerInstance.html for the full list of low-level APIs
  }

  ngAfterViewInit(): void {

    // The following code initiates a new instance of WebViewer.

    WebViewer({
      path: '../../wv-resources/lib',
      initialDoc: 'https://expresspaystub.s3.amazonaws.com/Staging/StaticFiles/From941/Form941_2019.pdf',
      disabledElements: [
        'header',
        'toolsHeader',
        'notesPanel'
      ],
    }, this.viewer.nativeElement).then(instance => {


      const { Annotations, annotManager } = instance;
      this.wvInstance = instance;

      //remove toolbars
      instance.setHeaderItems(function (header) {
        header.update([]);
      });

      //Set Zoom to fit to width
      let FitMode = instance.FitMode;
      instance.setFitMode(FitMode.FitWidth);


      //const customStyles = widget => {
      //    return {
      //      'background-color': 'lightblue',
      //      color: 'brown',
      //    };
      //};


      // now you can access APIs through this.webviewer.getInstance()
      //instance.openElement('notesPanel');
      // see https://www.pdftron.com/documentation/web/guides/ui/apis 
      // for the full list of APIs

      // or listen to events from the viewer element
      this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
        const [pageNumber] = e.detail;
        console.log(`Current page is ${pageNumber}`);
      });

      // or from the docViewer instance
      instance.docViewer.on('annotationsLoaded', () => {
        console.log('annotations loaded');
        const fieldManager = annotManager.getFieldManager();
        //fieldManager.forEachField(getFieldNameAndValue);

        //Annotations.WidgetAnnotation.getCustomStyles = customStyles;

      });


      


      //#region Get field data
      const getFieldNameAndValue = (field) => {
        // Do something with data
        const { name, value } = field;
        console.log(`Name: ${name}, Value: ${value}`);

        // Check children fields
        field.children.forEach(getFieldNameAndValue);
      }
      //#endregion

      //#region annotation Loaded
      //instance.docViewer.on('annotationsLoaded', () => {
      //  const fieldManager = annotManager.getFieldManager();
      //  fieldManager.forEachField(getFieldNameAndValue);
      //});
      //#endregion

      //#region Get value on field change
      annotManager.on('fieldChanged', (field, value) => {
        debugger;
        //let patern = /^\d*$/;
        //if (!patern.test(value)) {
        //  field.setValue('')
        //}
        console.log(`Name: ${field.name}, Value: ${field.annot}`);
        // Do something with data
      })

      //#endregion

      //#region set text box control
      //instance.docViewer.on('documentLoaded', () => {
      //  // set flags for multiline and required
      //  var widgetFlags = new Annotations.WidgetFlags();
      //  widgetFlags.set('Multiline', true);
      //  widgetFlags.set('Required', true);

      //  // create a form field
      //  const field = new Annotations.Forms.Field("ein1", {
      //    type: 'Tx',
      //    defaultValue: "some placeholder default text value",
      //    flags: widgetFlags
      //  });

      //  // create a widget annotation
      //  const widgetAnnot = new Annotations.TextWidgetAnnotation(field);

      //  // set position and size
      //  widgetAnnot.PageNumber = 1;
      //  widgetAnnot.X = 151;
      //  widgetAnnot.Y = 65;
      //  widgetAnnot.Width = 20;
      //  widgetAnnot.Height = 20;

      //  //add the form field and widget annotation
      //  annotManager.addAnnotation(widgetAnnot);
      //  annotManager.drawAnnotationsFromList([widgetAnnot]);
      //  annotManager.getFieldManager().addField(field);
      //})
      //#endregion

      //#region Save Button Get Data
      document.getElementById('btnSaveTax').onclick = function () {
        const fieldManager = annotManager.getFieldManager();
        fieldManager.forEachField(getFieldNameAndValue);
      }

      //#endregion

      //#region Add Sign control
      //instance.docViewer.on('documentLoaded', () => {
      //  let widgetFlags = new Annotations.WidgetFlags();
      //  widgetFlags.set('Required', true);

      //  // create a form field
      //  const field = new Annotations.Forms.Field("usign", {
      //    type: 'Sig',
      //    flags: widgetFlags
      //  });

      //  // create a widget annotation
      //  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
      //    appearance: '_DEFAULT',
      //    appearances: {
      //      _DEFAULT: {
      //        Normal: {
      //          data:
      //            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
      //          offset: {
      //            x: 100,
      //            y: 100,
      //          },
      //        },
      //      },
      //    },
      //  });

      //  // set position and size
      //  widgetAnnot.PageNumber = 2;
      //  widgetAnnot.X = 115;
      //  widgetAnnot.Y = 415;
      //  widgetAnnot.Width = 100;
      //  widgetAnnot.Height = 35;

      //  //add the form field and widget annotation
      //  annotManager.addAnnotation(widgetAnnot);
      //  annotManager.drawAnnotationsFromList([widgetAnnot]);
      //  annotManager.getFieldManager().addField(field);

      //  // create a form field
      //  const field1 = new Annotations.Forms.Field("usign2", {
      //    type: 'Sig',
      //    flags: widgetFlags
      //  });

      //  // create a widget annotation
      //  const widgetAnnot1 = new Annotations.SignatureWidgetAnnotation(field1, {
      //    appearance: '_DEFAULT',
      //    appearances: {
      //      _DEFAULT: {
      //        Normal: {
      //          data:
      //            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
      //          offset: {
      //            x: 100,
      //            y: 100,
      //          },
      //        },
      //      },
      //    },
      //  });

      //  // set position and size
      //  widgetAnnot1.PageNumber = 2;
      //  widgetAnnot1.X = 115;
      //  widgetAnnot1.Y = 415;
      //  widgetAnnot1.Width = 100;
      //  widgetAnnot1.Height = 35;

      //  //add the form field and widget annotation
      //  annotManager.addAnnotation(widgetAnnot1);
      //  annotManager.drawAnnotationsFromList([widgetAnnot1]);
      //  annotManager.getFieldManager().addField(field1);
      //})
      //#endregion

      //#region Custom UI
      document.getElementById('btnOpenTax').onclick = function () {
        const modal = {
          dataElement: 'meanwhileInFinlandModal',
          render: function renderCustomModal() {

            //let divStr = `<div class="customdiv">
            //                <div>
            //                  text required
            //                </div>
            //              </div>`;
            let div = document.createElement("div");
            //div.style.color = 'white';
            //div.style.backgroundColor = 'hotpink';
            //div.style.padding = '20px 40px';
            //div.style.borderRadius = '5px';
            div.innerText = 'Required';
            div.setAttribute('class', 'customdiv');
            return div

            //return divStr;
          }
        }
        instance.setCustomModal(modal);
        instance.openElements([modal.dataElement]);
        //instance.showErrorMessage('required');
      }
      //#endregion
    })
  }
}
