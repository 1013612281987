import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebviewerComponent } from './webviewer/webviewer.component';
import { MatButtonModule } from '@angular/material/button'
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { MatCommonModule, MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { CustomviewerComponent } from './customviewer/customviewer.component';

@NgModule({
  declarations: [
    AppComponent,
    WebviewerComponent,
    CustomviewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatCommonModule,
    MatRippleModule,
    MatNativeDateModule,
    OverlayModule,
    A11yModule,
    BidiModule,
    ObserversModule,
    PortalModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
