import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebviewerComponent } from './webviewer/webviewer.component';
import { CustomviewerComponent } from './customviewer/customviewer.component';


const routes: Routes = [
  { path: '', component: CustomviewerComponent, pathMatch: 'full' },
  { path: 'webviewer', component: WebviewerComponent },
  { path: 'opsviewer', component: CustomviewerComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
