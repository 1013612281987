<div class="page">
  <div #opsviewer class="viewer"></div>
  <div class="spacer20"></div>
  <div class="clearfix"></div>
  <div style="float:right;">
    <button mat-raised-button type="button" id="btnSaveTax" color="primary">
      Save
    </button>
  </div>
</div>



